import React from "react";
import clsx from "clsx";
import { Layout, centerInGrid } from "components";
import { SEO } from "utils";

const PageNotFound = () => {
  return (
    <Layout>
      <SEO title="404: Nicht gefunden" />
      <div className={clsx("flex flex-col max-w-4xl", centerInGrid)}>
        <h2 className="text-4xl my-3">
          🤷 Diese Seite existiert leider nicht!
        </h2>
      </div>
    </Layout>
  );
};

export default PageNotFound;
